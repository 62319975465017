import 'ol/ol.css';

import {Feature, Map, Overlay , View} from 'ol';
import {defaults, FullScreen, MousePosition, OverviewMap, Rotate, ScaleLine, Zoom, ZoomSlider, ZoomToExtent} from 'ol/control';
import * as coordinate from 'ol/coordinate';
import {easeOut} from 'ol/easing';
import {Draw} from 'ol/interaction';
import {createEmpty, getWidth, getHeight, extend} from 'ol/extent';
import {KML, WKT} from 'ol/format';
import * as PolygonGeom from 'ol/geom/Polygon';
import {Circle, LineString, Polygon, Point} from 'ol/geom';
import {Image, Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import * as loadingstrategy from 'ol/loadingstrategy';
import {unByKey} from 'ol/Observable';
import * as proj from 'ol/proj';
import {getVectorContext} from 'ol/render';
import { BingMaps, Cluster, ImageWMS, OSM, TileImage, Vector as VectorSource, XYZ } from 'ol/source';
import {getArea, getDistance, getLength} from 'ol/sphere';
import { Circle as CircleStyle, Fill, Stroke, Style, Text, Icon } from 'ol/style';
import TileGrid from 'ol/tilegrid/TileGrid';
import {toPng} from 'html-to-image';

const EPSGType = {
    EPSG4326: { value: 'EPSG:4326' },
    EPSG3857: { value: 'EPSG:3857' }
};

const MapStyle = {
    GoogleMapRoad: {},
    GoogleMapHybrid: {},
    GoogleMapDark: {},
    BaiduMap: {},
    BaiduHybrid: {},
    BingMap: {},
    BingMapHybrid: {},
    OpenStreetMap: {},
    MapBox: {},
    MapBoxHybrid: {},
    GaoDe: {},
    GaoDeHybrid: {}
};

function createMapFactory(bingMapsKey, mapBoxKey) {
    var obj = {};

    obj.createMap = function(target, wmsUrl, options) {

        var mapControls;
        if(options.disableControlls === true){
            mapControls = [];
        }
        else{
            mapControls = defaults({ zoomOptions: options.zoomTipLabel });
        }

        //console.log(mapControls);
        mapControls.push(new FullScreen());

        var map = new Map({
            target: target,
            layers: [],
            controls: mapControls,
            view: new View({
                center: options.center,
                zoom: options.zoomValue.default,
                minZoom: options.zoomValue.minZoom,
                maxZoom: options.zoomValue.maxZoom
            })
        });

		map.targetId = target;

        //BaseMap
        (function() {
            var baseMapLayers = [];
            map.addBaseMapLaysersToMap = function(mapSelect) {
                baseMapLayers = createBaseLayers(mapSelect, bingMapsKey, mapBoxKey);
                baseMapLayers.forEach(function(layer) {
                    map.addLayer(layer);
                });
            };

            map.getBaseMapLaysers = function(){
                return baseMapLayers;
            }

            map.setBaseMap = function(mapSelect, createOverviewMap, mapOverviewMapTipLabel) {
                baseMapLayers.forEach(function(layer) {
                    map.removeLayer(layer);
                });

                map.addBaseMapLaysersToMap(mapSelect);

                if (createOverviewMap === true) {
                    var overviewMapControl = new OverviewMap({
                        tipLabel: mapOverviewMapTipLabel,
                        layers: baseMapLayers
                    });
                    map.addControl(overviewMapControl);
                }
            };
        })();

        //Layers
        (function() {
            var dataLayers = {};
            var popupOverlay = {};
            var zIndex = 100;

            map.createImageLayer = function(id, option) {
                var format = "image/png";

                if (option.filter === undefined) option.filter = "";
                if (option.opacity === undefined) option.opacity = 1;
                if (option.styles === undefined) option.styles = "";
                if (option.layerGeoId === undefined) option.layerGeoId = "";
                if (option.viewparams === undefined) option.viewparams = "";
                if (option.zIndex === undefined) option.zIndex = zIndex;

                var params = {
                    FORMAT: format,
                    VERSION: '1.1.1',
                    STYLES: option.styles,
                    LAYERS: option.layerName,
                    FILTER: '',
                    CQL_FILTER: option.filter,
                    FEATUREID: '',
                    LayerGeoId: option.layerGeoId,
                    viewparams: option.viewparams
                };

                if (option.filter !== undefined) {
                    params.CQL_FILTER = option.filter;
                }

                const blankSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
                var previousImage;
                var source = new ImageWMS({
                    //ratio: 1,
                    url: wmsUrl,
                    params: params,
                    //,crossOrigin: 'anonymous'
                    imageLoadFunction: function(image, src) {
                        if (previousImage && previousImage.state === 1) {
                            // abort previous request
                            previousImage.getImage().src = blankSrc;
                        }
                        previousImage = image;

                        image.getImage().src = src;
                    }
                });

                //var oldLayer = undefined;
                if (dataLayers[id] !== undefined) {
                    //oldLayer = dataLayers[id];
                    map.removeLayer(dataLayers[id]);
                }

                // ReSharper disable once StringLiteralTypo
                source.on('imageloadend',
                    function() {
                        //if (oldLayer !== undefined) {
                        //    map.removeLayer(oldLayer);
                        //}

                        if (isFunction(option.loaded)) {
                            option.loaded();
                        }
                    });

                // ReSharper disable once StringLiteralTypo
                source.on('imageloaderror',
                    function() {
                        //if (oldLayer !== undefined) {
                        //    map.removeLayer(oldLayer);
                        //}

                        if (isFunction(option.loaderror)) {
                            option.loaderror();
                        }
                    });

                var imageLayer = new Image({
                    source: source,
                    opacity: option.opacity
                });

                dataLayers[id] = imageLayer;
                imageLayer.setZIndex(option.zIndex);
                map.addLayer(imageLayer);
            };

            map.removeImageLayer = function(id) {
                map.removeLayer(dataLayers[id]);
                delete dataLayers[id];
            };

            map.updateFilter = function(id, filter) {
                if (dataLayers[id] !== undefined) {
                    var filterParamsc = {
                        CQL_FILTER: filter
                    };
                    dataLayers[id].getSource().updateParams(filterParamsc);
                }
            };

            map.setOpacity = function(id, opacity) {
                if (dataLayers[id] !== undefined) {
                    dataLayers[id].setOpacity(opacity / 100.0);
                }
            };

            //Events
            map.singleclick = function(callbackFunction) {
                map.on('singleclick',
                    function(evt) {
                        var view = map.getView();
                        var viewResolution = (view.getResolution());
                        var projection = view.getProjection();

                        var urlData = [];
                        for (var key in dataLayers) {
                            if (dataLayers.hasOwnProperty(key)) {
                                var layer = dataLayers[key];

                                var url = layer.getSource().getGetFeatureInfoUrl(
                                    evt.coordinate,
                                    viewResolution,
                                    projection,
                                    { 'INFO_FORMAT': 'application/json' });

                                urlData.push({ url: url, layerId: key });
                            }
                        }

                        callbackFunction(evt, urlData);
                    });
            };

            map.addPopupOverlay = function(popupId) {
                var overlay;

                if (popupOverlay[popupId] === undefined) {
                    var container = document.getElementById(popupId);

                    overlay = new Overlay({
                        element: container,
                        autoPan: true,
                        autoPanAnimation: {
                            duration: 250
                        }
                    });

                    popupOverlay[popupId] = overlay;
                    overlay.setMap(map);
                } else {
                    overlay = popupOverlay[popupId];
                }

                return overlay;
            };

            map.removePopupOverlay = function(popupId) {
                if (popupOverlay[popupId] !== undefined) {
                    var overlay = popupOverlay[popupId];
                    overlay.setPosition(undefined);
                    map.removeOverlay(overlay);
                    delete popupOverlay[popupId];
                }
            };
        })();

        //Draw
        (function() {
            var circleDataLayers = {};
			var pointDataLaysers = {};
            var zIndex = 1000;

			map.createPoint = function(id, x, y, color){
				if (!color) {
                    color = '#3377ff';
                }

				var pointGeom = new Point([x, y]);
				var pointFeature = new Feature(pointGeom);
                var vectorSource = new VectorSource({ projection: 'EPSG:3857' });
                vectorSource.addFeatures([pointFeature]);
                var vector =  new VectorLayer({
                    source: vectorSource,
                    style: [
                        new Style({
                            /*
                            fill: new Fill({
                                color: 'rgba(255, 255, 255, 0.2)'
                            }),
                            stroke: new Stroke({
                                color: color,
                                width: 2
                            }),
                            image: new CircleStyle({
                                radius: 7,
                                fill: new Fill({
                                    color: color
                                })
                            })
                            */
							image: new Icon({
                                scale: 0.17,
                                src: '/img/maps/QA_pin03.svg'
                                //src: '/img/maps/QA_pin02.svg'
                                //src: '/img/pin.png'
                            })
                        })
                    ]
                });

				pointDataLaysers[id] = vector;
                vector.setZIndex(zIndex);
                map.addLayer(vector);
			}

			map.createPointByLonLat = function(id, x, y, color){
				if (!color) {
                    color = '#3377ff';
                }

				var c1 = proj.transform([x,y], 'EPSG:4326', 'EPSG:3857');
				map.createPoint(id, c1[0], c1[1], color);
			}

			map.removePoint = function(id) {
                map.removeLayer(pointDataLaysers[id]);
                delete circleDataLayers[id];
            };

            map.removeAllPoint = function() {
                for (var key in pointDataLaysers) {
                    if (pointDataLaysers.hasOwnProperty(key)) {
                        map.removeCircle(key);
                    }
                }
            };

            map.createShapeByWKT = function(id, wkt) {
                var projection = {
                    dataProjection: 'EPSG:3857',
                    featureProjection: 'EPSG:3857'
                };

                var wktReader = new WKT();
                var feature = wktReader.readFeature(wkt, projection);
                feature.setId(id);
                return feature;
            };

            map.drawFeature = function(feature, color){
                var vectorLayer = new VectorLayer({
                    source: new VectorSource({ features: [feature] }),
                    style: new Style({
                        fill: new Fill({
                            color: 'rgba(255, 255, 255, 0.2)'
                        }),
                        stroke: new Stroke({
                            color: color,
                            width: 2
                        }),
                        image: new CircleStyle({
                            radius: 7,
                            fill: new Fill({
                                color: color
                            })
                        })
                    })
                });

                vectorLayer.setZIndex(10000);
                map.addLayer(vectorLayer);
            };

            map.createCircle = function(id, circleSize, x, y, color) {
                if (!color) {
                    color = 'red';
                }

                ////var circle = new ol.geom.Circle(ol.proj
                ////    .transform([x, y], 'EPSG:4326', 'EPSG:3857'),
                ////    circleSize);

                ////var wgs84Sphere = new ol.Sphere(6378137);

                var c1 = proj.transform([x,y], 'EPSG:3857', 'EPSG:4326');
				map.createCircleByLonLat(id, circleSize, c1[0], c1[1], color);
            };

			map.createCircleByLonLat = function(id, circleSize, x, y, color) {
                if (!color) {
                    color = 'red';
                }

                var c1 = [x,y];
                var circle = PolygonGeom.circular(c1, circleSize, 64, 6378137).transform('EPSG:4326', 'EPSG:3857');

                var circleFeature = new Feature(circle);
                var vectorSource = new VectorSource({ projection: 'EPSG:3857' });
                vectorSource.addFeatures([circleFeature]);
                var vector =  new VectorLayer({
                    source: vectorSource,
                    style: [
                        new Style({
                            stroke: new Stroke({
                                color: color,
                                width: 3
                            }),
                            fill: new Fill({
                                color: 'rgba(0, 0, 255, 0.1)'
                            })
                        })
                    ]
                });

                circleDataLayers[id] = vector;
                vector.setZIndex(zIndex);
                map.addLayer(vector);
            };

            map.removeCircle = function(id) {
                map.removeLayer(circleDataLayers[id]);
                delete circleDataLayers[id];
            };

            map.removeAllCircle = function() {
                for (var key in circleDataLayers) {
                    if (circleDataLayers.hasOwnProperty(key)) {
                        map.removeCircle(key);
                    }
                }
            };
        })();

        //Measure
        (function() {

            var geomObjects = [];
            var overlayObjects = [];

            var zIndex = 1500;
            //var kiwiMeasureColor = "#ffcc33";
            var kiwiMeasureColor = "#003c89";
            var measureSupClass = "margin-right: 2px;top: -5px;";

            //Circle, Point, Polygon, LineString
            var type = 'Polygon';

            var draw; // global so we can remove it later

            /**
             * Currently drawn feature.
             * @type {import("../src/ol/Feature.js").default}
             */
            var sketch;


            /**
             * The help tooltip element.
             * @type {HTMLElement}
             */
            var helpTooltipElement;


            /**
             * Overlay to show the help messages.
             * @type {Overlay}
             */
            var helpTooltip;


            /**
             * The measure tooltip element.
             * @type {HTMLElement}
             */
            var measureTooltipElement;


            /**
             * Overlay to show the measurement.
             * @type {Overlay}
             */
            var measureTooltip;


            /**
             * Message to show when the user is drawing a polygon.
             * @type {string}
             */
            //var continuePolygonMsg = 'Click to continue drawing the polygon';


            /**
             * Message to show when the user is drawing a line.
             * @type {string}
             */
            //var continueLineMsg = 'Click to continue drawing the line';

            var source = new VectorSource();

            map.getViewport().addEventListener('mouseout', function() {
                if (helpTooltipElement !== undefined) {
                    helpTooltipElement.classList.add('hidden');
                }
            });

            var vector = new VectorLayer({
                source: source,
                style: new Style({
                    /*
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)'
                    }),
                    stroke: new Stroke({
                        color: kiwiMeasureColor,
                        width: 2
                    }),
                    image: new CircleStyle({
                        radius: 7,
                        fill: new Fill({
                            color: kiwiMeasureColor
                        })
                    })
                    */
                    image: new Icon({
                        scale: 0.17,
                        src: '/img/maps/QA_pin03.svg'
                        //src: '/img/maps/QA_pin02.svg'
                        //src: '/img/pin.png'
                    }),

                })
            });

            ///**
            //* Handle pointer move.
            //* @param {import("../src/ol/MapBrowserEvent").default} evt The event.
            //*/
            //var pointerMoveHandler = function(evt) {
            //    if (evt.dragging) {
            //        return;
            //    }
            //    /** @type {string} */
            //    var helpMsg = 'Click to start drawing';

            //    if (sketch) {
            //        var geom = sketch.getGeometry();
            //        if (geom instanceof Polygon) {
            //            helpMsg = continuePolygonMsg;
            //        } else if (geom instanceof LineString) {
            //            helpMsg = continueLineMsg;
            //        }
            //    }

            //    helpTooltipElement.innerHTML = helpMsg;
            //    helpTooltip.setPosition(evt.coordinate);

            //    helpTooltipElement.classList.remove('hidden');
            //};
            //map.on('pointermove', pointerMoveHandler);

            /**
              * Format length output.
              * @param {LineString} line The line.
              * @return {string} The formatted length.
              */
            var formatLength = function(line) {
                var length = getLength(line);
                var output;
                if (length > 100) {
                    output = (Math.round(length / 1000 * 100) / 100) +
                        ' ' + 'km';
                } else {
                    output = (Math.round(length * 100) / 100) +
                        ' ' + 'm';
                }
                return output;
            };

            var formatCircle = function (circle) {
                var radius;
                //if (geodesicCheckbox.checked) {
                    var sourceProj = map.getView().getProjection();
                    var c1 = proj.transform(circle.getFirstCoordinate(), sourceProj, 'EPSG:4326');
                    var c2 = proj.transform(circle.getLastCoordinate(), sourceProj, 'EPSG:4326');
                    radius = getDistance(c1, c2);
                //} else {
                //    radius = circle.getRadius();
                //}
                var area = radius * radius * Math.PI;
                var length = 2 * radius * Math.PI;
                var output;
                if (area > 10000) {
                    output = 'r:' + (Math.round(radius / 1000 * 100) / 100) +
                        ' ' + 'km ' + (Math.round(area / 1000000 * 100) / 100) +
                        ' ' + 'km<sup style="' + measureSupClass + '">2</sup>   ' + (Math.round(length / 1000 * 100) / 100) +
                        ' ' + 'km';
                } else {
                    output = 'r:' + (Math.round(radius * 100) / 100) +
                        ' ' + 'm ' + (Math.round(area * 100) / 100) +
                        ' ' + 'm<sup style="' + measureSupClass + '">2</sup>   ' + (Math.round(length * 100) / 100) +
                        ' ' + 'm';
                }
                return output;
            };

            /**
             * Format area output.
             * @param {Polygon} polygon The polygon.
             * @return {string} Formatted area.
             */
            var formatArea = function(polygon) {
                var area = getArea(polygon);
                var output;
                if (area > 10000) {
                    output = (Math.round(area / 1000000 * 100) / 100) +
                        ' ' + 'km<sup>2</sup>';
                } else {
                    output = (Math.round(area * 100) / 100) +
                        ' ' + 'm<sup>2</sup>';
                }
                return output;
            };

            function addInteraction(showTip, drawStartCallBack, drawEndCallBack) {

                draw = new Draw({
                    source: source,
                    type: type,
                    //freehand: true,
                    style: new Style({
                        fill: new Fill({
                            color: 'rgba(255, 255, 255, 0.2)'
                        }),
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 0, 0.5)',
                            lineDash: [10, 10],
                            width: 2
                        }),
                        image: new CircleStyle({
                            radius: 5,
                            stroke: new Stroke({
                                color: 'rgba(0, 0, 0, 0.7)'
                            }),
                            fill: new Fill({
                                color: 'rgba(255, 255, 255, 0.2)'
                            })
                        })
                    })
                });
                map.addInteraction(draw);

                createMeasureTooltip();
                createHelpTooltip();

                var listener;
                draw.on('drawstart',
                    function(evt) {

						if (isFunction(drawStartCallBack)) {
							//drawStartCallBack();
						}


                        // set sketch
                        sketch = evt.feature;

                        /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
                        var tooltipCoord = evt.coordinate;

                        var targetGeom = sketch.getGeometry();
                        //if (targetGeom instanceof Point) {
                            //lastGeo = targetGeom;
                        //}

                        geomObjects.push(targetGeom);

                        listener = sketch.getGeometry().on('change', function(evt) {
                            var geom = evt.target;
                            var output;
                            if (geom instanceof Polygon) {
                                output = formatArea(geom);
                                tooltipCoord = geom.getInteriorPoint().getCoordinates();
                            } else if (geom instanceof LineString) {
                                output = formatLength(geom);
                                tooltipCoord = geom.getLastCoordinate();
                            }
                            else if (geom instanceof Circle) {
                                output = formatCircle(geom);
                                tooltipCoord = geom.getLastCoordinate();
                            }

							if(showTip === true){
								measureTooltipElement.innerHTML = output;
								measureTooltip.setPosition(tooltipCoord);
							}
                        });
                    });

                draw.on('drawend',
                    function() {
						if(showTip === true){
							measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
							measureTooltip.setOffset([0, -7]);
							overlayObjects.push(measureTooltip);
						}
                        // unset sketch
                        sketch = null;
                        // unset tooltip so that a new one can be created
                        measureTooltipElement = null;
                        createMeasureTooltip();
                        unByKey(listener);

						if (isFunction(drawEndCallBack)) {
							drawEndCallBack(geomObjects[geomObjects.length - 1]);
                        }
                    });
            }

            /**
             * Creates a new help tooltip
             */
            function createHelpTooltip() {
                if (helpTooltipElement) {
                    helpTooltipElement.parentNode.removeChild(helpTooltipElement);
                }
                helpTooltipElement = document.createElement('div');
                helpTooltipElement.className = 'ol-tooltip hidden';
                helpTooltip = new Overlay({
                    element: helpTooltipElement,
                    offset: [15, 0],
                    positioning: 'center-left'
                });
                map.addOverlay(helpTooltip);
            }

            /**
             * Creates a new measure tooltip
             */
            function createMeasureTooltip() {
                if (measureTooltipElement) {
                    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
                }
                measureTooltipElement = document.createElement('div');
                measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
                measureTooltip = new Overlay({
                    element: measureTooltipElement,
                    offset: [0, -15],
                    positioning: 'bottom-center'
                });
                map.addOverlay(measureTooltip);
            }

            map.addLayer(vector);
            vector.setZIndex(zIndex);

            map.StartMeasure = function(drawType, showTip, drawStartCallBack, drawEndCallBack) {
                type = drawType;
                addInteraction(showTip, drawStartCallBack, drawEndCallBack);
            };

            map.StopMeasure = function() {
                map.removeInteraction(draw);
                //console.log(geomObjects);
            };

            map.ClearMeasure = function() {
                source = new VectorSource();
                vector.setSource(source);

                overlayObjects.forEach(function (overlay) {
                    map.removeOverlay(overlay);
                });

                overlayObjects = [];
                geomObjects = [];
            };

        })();

        //Cluster
        (function() {
            var clusterVector = {};
            map.createCluster = function(url) {
                var vector = new VectorLayer({
                    source: new Cluster({
                        distance: 40,
                        source: new VectorSource({
                            url: function(extent) {
                                return url +
                                    '&bbox=' +
                                    extent.join(',');
                            },
                            format: new KML({
                                extractStyles: false
                            }),
                            strategy: loadingstrategy.bbox
                        })
                    }),
                    style: styleFunction
                });

                function calculateClusterInfo(resolution, styleParam) {
                    styleParam.maxFeatureCount = 0;
                    var features = vector.getSource().getFeatures();
                    var feature, radius;
                    //console.log('calculateClusterInfo' + features.length);
                    for (var i = features.length - 1; i >= 0; --i) {
                        feature = features[i];
                        //console.log(feature);
                        var originalFeatures = feature.get('features');
                        var extent = createEmpty();
                        var j, jj;
                        var competitorCount = 0;
                        var mySiteCount = 0;
                        for (j = 0, jj = originalFeatures.length; j < jj; ++j) {
                            //console.log(originalFeatures[j].get('name'));
                            //console.log(originalFeatures[j].get('name'));
                            if (originalFeatures[j].get('name').toString().indexOf('SimpleCOMPETITOR') === 0) {
                                competitorCount++;
                            } else {
                                mySiteCount++;
                            }
                            extend(extent, originalFeatures[j].getGeometry().getExtent());
                        }
                        styleParam.maxFeatureCount = Math.max(styleParam.maxFeatureCount, jj);
                        radius = 0.25 *
                            (getWidth(extent) + getHeight(extent)) /
                            resolution;
                        //console.log(radius);
                        feature.set('radius', radius);
                        feature.set('competitorCount', competitorCount);
                        feature.set('mySiteCount', mySiteCount);
                    }
                }

                var styleParam = {
                    maxFeatureCount: 0,
                    currentResolution: ''
                };

                function styleFunction(feature, resolution) {
                    var features = vector.getSource().getFeatures();
                    for (var i = features.length - 1; i >= 0; --i) {
                        var featureData = features[i];
                        if (featureData.get('radius') === undefined) {
                            styleParam.currentResolution = '';
                            break;
                        }
                    }

                    if (resolution !== styleParam.currentResolution) {
                        calculateClusterInfo(resolution, styleParam);
                        styleParam.currentResolution = resolution;
                    }
                    var size = feature.get('features').length;

                    return new Style({
                        image: new CircleStyle({
                            radius: feature.get('radius'),
                            fill: new Fill({
                                color: [255, 153, 0, Math.min(0.8, 0.4 + size / styleParam.maxFeatureCount)]
                            })
                        }),
                        text: new Text({
                            text: feature.get('mySiteCount') +
                                ':' +
                                feature.get('competitorCount'), // + ' / ' + size.toString(),
                            fill: new Fill({
                                color: '#fff'
                            }),
                            stroke: new Stroke({
                                color: 'rgba(0, 0, 0, 0.6)',
                                width: 3
                            })
                        })
                    });
                }

                clusterVector[url] = vector;
                map.addLayer(vector);
            };

            map.removeCluster = function(url) {
                map.removeLayer(clusterVector[url]);
            };

        })();

        //Controls
        map.createFullScreen = function(mapFullScreenTipLabel) {
            var control = new FullScreen({
                tipLabel: mapFullScreenTipLabel
            });
            map.addControl(control);
        };

        map.createRotate = function(mapRotateTipLabel) {
            var control = new Rotate({
                autoHide: false,
                tipLabel: mapRotateTipLabel
            });
            map.addControl(control);
        };

        map.createScaleLine = function() {
            var control = new ScaleLine();
            map.addControl(control);
        };

        //Zoom
        map.createZoom = function(zoomInTipLabel, zoomOutTipLabel) {
            var control = new Zoom({
                zoomInTipLabel: zoomInTipLabel,
                zoomOutTipLabel: zoomOutTipLabel
            });
            map.addControl(control);
        };

        map.createZoomSlider = function() {
            var control = new ZoomSlider();
            map.addControl(control);
        };

        map.createZoomToExtend = function(mapZoomToExtentTipLabel) {
            var control = new ZoomToExtent({
                tipLabel: mapZoomToExtentTipLabel,
                extent: [
                    8500000.00000000, 2900000.00000000,
                    14000000.00000000, 7100000.00000000
                ]
            });
            map.addControl(control);
        };

        //fit
        map.fitFeature = function(feature){
            var boundingExtent = feature.getGeometry().getExtent();
            this.getView().fit(boundingExtent, this.getSize());
            this.getView().setZoom(this.getView().getZoom() - 1);
        };

        //Center
        map.centerByLonLat = function(x, y) {
            this.getView().setCenter(proj.fromLonLat([parseFloat(x), parseFloat(y)]));
        };

        map.centerFromWM = function(x, y) {
            this.getView().setCenter([parseFloat(x), parseFloat(y)]);
        };

        //Animate Point
        map.animatePointByLonLat = function(x, y) {
            var c1 = proj.transform([x,y], 'EPSG:4326', 'EPSG:3857');
            map.animatePoint(c1[0], c1[1]);
        };

        map.animatePoint = function(x, y) {
            //console.log(x, y);
            var baseMap = map.getBaseMapLaysers();
            var tileLayer = baseMap[0];

            var source = new VectorSource({
                wrapX: false
            });
            var vector = new VectorLayer({
                source: source
            });
            map.addLayer(vector);

            function addRandomFeature(x, y) {
                //console.log(x, y);
                var geom = new Point([x, y]);
                var feature = new Feature(geom);
                source.addFeature(feature);
            }

            var duration = 10000;
            function flash(feature) {
                var start = new Date().getTime();
                var listenerKey = tileLayer.on('postrender', animate);

                function animate(event) {
                    var vectorContext = getVectorContext(event);
                    var frameState = event.frameState;
                    var flashGeom = feature.getGeometry().clone();
                    var elapsed = frameState.time - start;
                    var elapsedRatio = elapsed / duration;
                    // radius will be 5 at start and 30 at end.
                    //console.log(elapsedRatio);
                    // if (elapsedRatio >= 1) {
                    //     elapsedRatio = 0.99;
                    // }
                    var radius = (elapsedRatio % 0.2) * 3 * 25 + 7;
                    //var radius = easeOut(elapsedRatio) * 25 + 5;
                    //var opacity = easeOut(1 - elapsedRatio);
                    var opacity = 1 - (elapsedRatio % 0.2) * 3;

                    var style = new Style({
                        image: new CircleStyle({
                            radius: radius,
                            stroke: new Stroke({
                                color: 'rgba(255, 0, 0, ' + opacity + ')',
                                width: 0.25 + opacity
                            })
                        })
                    });

                    vectorContext.setStyle(style);
                    vectorContext.drawGeometry(flashGeom);
                    if (elapsed > duration) {
                    unByKey(listenerKey);
                        return;
                    }
                    // tell OpenLayers to continue postrender animation
                    map.render();
                }
            }

            source.on('addfeature', function(e) {
                flash(e.feature);
            });

            //window.setInterval(addRandomFeature, 1000);
            addRandomFeature(x, y);
        };

        //mousePosition
        (function() {
            var mousePosition;
            map.createMousePosition = function(divId, epsg, precision, className) {

                if (!className) {
                    className = 'custom-mouse-position';
                }

                if (!precision) {
                    precision = 2;
                }

                if (!epsg) {
                    epsg = EPSGType.EPSG4326;
                }

                var control = new MousePosition({
                    coordinateFormat: coordinate.createStringXY(4),
                    projection: epsg.value,
                    className: className,
                    target: document.getElementById(divId),
                    undefinedHTML: '&nbsp;'
                });

                var format = coordinate.createStringXY(precision);
                control.setCoordinateFormat(format);

                mousePosition = control;
                map.addControl(mousePosition);
            };
            map.chamgeMousePositionStyle = function(epsg, precision) {
                if (!precision) {
                    precision = 2;
                }

                if (!epsg) {
                    epsg = EPSGType.EPSG4326;
                }

                if (mousePosition) {
                    var format = coordinate.createStringXY(precision);
                    mousePosition.setCoordinateFormat(format);
                    mousePosition.setProjection(epsg.value);
                }
            };
        })();

        //googleTrafficLayer
        (function() {
            var googleTrafficLayer;
            map.addGoogleTrafficLayer = function() {
                googleTrafficLayer = new TileLayer({
                    // ReSharper disable once InconsistentNaming
                    source: new OSM({
                        // ReSharper disable once StringLiteralTypo
                        url:
                            'https://mt{0-3}.google.com/vt/lyrs=h,traffic%7Cseconds_into_week:-1&x={x}&y={y}&z={z}&hl=en&style=3',
                        attributions: '',
                        crossOrigin: 'anonymous'
                    })
                });

                map.addLayer(googleTrafficLayer);
                googleTrafficLayer.setZIndex(1);
            };

            map.removeGoogleTrafficLayer = function() {
                map.removeLayer(googleTrafficLayer);
            };
        })();

        //gaodeTrafficLayer
        (function() {
            var gaodeTrafficLayer;
            map.addGaodeTrafficLayer = function() {

                gaodeTrafficLayer = new TileLayer({
                    source: new OSM({
                        url: 'https://tm.amap.com/trafficengine/mapabc/traffictile?v=1.0&z={z}&y={y}&x={x}&t=' +
                            new Date().getTime(),
                        crossOrigin: 'anonymous',
                        attributions: ''
                    })
                });

                map.addLayer(gaodeTrafficLayer);
                gaodeTrafficLayer.setZIndex(1);
            };

            map.removeGaodeTrafficLayer = function() {
                map.removeLayer(gaodeTrafficLayer);
            };
        })();

        //baiduTrafficLayer
        (function() {
            var baiduTrafficLayer;
            map.addBaiduTraffic = function() {
                baiduTrafficLayer = createBaiduMap("traffic");

                map.addLayer(baiduTrafficLayer);
                baiduTrafficLayer.setZIndex(1);
            };

            map.removeBaiduTraffic = function() {
                map.removeLayer(baiduTrafficLayer);
            };
        })();

        //toPng
        map.toPng = function() {
            var exportOptions = {
                filter: function(element) {
                    return element.className ? element.className.indexOf('ol-control') === -1 : true;
                }
            };

            map.once('rendercomplete', function() {
                toPng(map.getTargetElement(), exportOptions)
                    .then(function(dataURL) {

                        var link = document.createElement('a');
                        link.download = "map.png";
                        link.href = dataURL;
                        link.click();
                    });
            });
            map.renderSync();
        };

		map.setZoom = function(zoom) {
			map.getView().setZoom(zoom);
		}

		map.getZoom = function(){
			return map.getView().getZoom();
		}

		map.convert4326To3857 = function(x, y){
			var c1 = proj.transform([x,y], 'EPSG:4326', 'EPSG:3857');
			return c1;
		}

        return map;
    };

    return obj;
}


function createBaseLayers(mapSelect, bingMapsKey, mapBoxKey) {
    var layers = [];
    var layer;

	//google map
    //h = roads only
    //m = standard roadmap
    //p = terrain
    //r = somehow altered roadmap
    //s = satellite only
    //t = terrain only
    //y = hybrid
    //&hl=zh-CN
    if (mapSelect === MapStyle.GoogleMapRoad) {
        (function() {
            var layer = new TileLayer();
            // ReSharper disable once InconsistentNaming
            layer.setSource(new OSM({
                //url: 'http://192.168.0.142:7080/PBS/rest/services/GoogleMapsRoad/MapServer/WMTS/tile/1.0.0/GoogleMapsRoad/default/nativeTileMatrixSet/{z}/{y}/{x}.png',
                url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en',
                attributions: '',
                crossOrigin: 'anonymous'
            }));
            layers.push(layer);
        })();
    }
    else if (mapSelect === MapStyle.GoogleMapHybrid) {
        (function() {
            var layer = new TileLayer();
            layer.setSource(new OSM({
                url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}&hl=en',
                attributions: '',
                crossOrigin: 'anonymous'
            }));
            layers.push(layer);
        })();
    }
    else if (mapSelect === MapStyle.GoogleMapDark) {
        (function() {
            var layer = new TileLayer();
            // ReSharper disable once InconsistentNaming
            layer.setSource(new OSM({
                // ReSharper disable StringLiteralTypo
                url:
                    'https://maps.googleapis.com/maps/vt?pb=!1m5!1m4!1i{z}!2i{x}!3i{y}!4i256!2m3!1e0!2sm!3i403099950!3m14!2szh-TW!3sUS!5e18!12m1!1e68!12m3!1e37!2m1!1ssmartmaps!12m4!1e26!2m2!1sstyles!2zcy5lOmd8cC5jOiNmZjFkMmM0ZCxzLmU6bC50LmZ8cC5jOiNmZjhlYzNiOSxzLmU6bC50LnN8cC5jOiNmZjFhMzY0NixzLnQ6MTd8cy5lOmcuc3xwLmM6I2ZmNGI2ODc4LHMudDoyMXxzLmU6bHxwLnY6b2ZmLHMudDoyMXxzLmU6bC50LmZ8cC5jOiNmZjY0Nzc5ZSxzLnQ6MTh8cy5lOmcuc3xwLmM6I2ZmNGI2ODc4LHMudDo4MXxzLmU6Zy5zfHAuYzojZmYzMzRlODcscy50OjgyfHMuZTpnfHAuYzojZmYwMjNlNTgscy50OjJ8cy5lOmd8cC5jOiNmZjI4M2Q2YSxzLnQ6MnxzLmU6bC50fHAudjpvZmYscy50OjJ8cy5lOmwudC5mfHAuYzojZmY2ZjliYTUscy50OjJ8cy5lOmwudC5zfHAuYzojZmYxZDJjNGQscy50OjQwfHMuZTpnLmZ8cC5jOiNmZjAyM2U1OCxzLnQ6NDB8cy5lOmwudC5mfHAuYzojZmYzQzc2ODAscy50OjN8cy5lOmd8cC5jOiNmZjMwNGE3ZCxzLnQ6M3xzLmU6bC50LmZ8cC5jOiNmZjk4YTViZSxzLnQ6M3xzLmU6bC50LnN8cC5jOiNmZjFkMmM0ZCxzLnQ6NDl8cy5lOmd8cC5jOiNmZjJjNjY3NSxzLnQ6NDl8cy5lOmcuc3xwLmM6I2ZmMjU1NzYzLHMudDo0OXxzLmU6bC50LmZ8cC5jOiNmZmIwZDVjZSxzLnQ6NDl8cy5lOmwudC5zfHAuYzojZmYwMjNlNTgscy50OjUxfHMuZTpsfHAudjpvZmYscy50OjR8cy5lOmwudC5mfHAuYzojZmY5OGE1YmUscy50OjR8cy5lOmwudC5zfHAuYzojZmYxZDJjNGQscy50OjY1fHMuZTpnLmZ8cC5jOiNmZjI4M2Q2YSxzLnQ6NjZ8cy5lOmd8cC5jOiNmZjNhNDc2MixzLnQ6NnxzLmU6Z3xwLmM6I2ZmMGUxNjI2LHMudDo2fHMuZTpsLnQuZnxwLmM6I2ZmNGU2ZDcw!4e0!5m1!5f2&token=2862',
                // ReSharper restore StringLiteralTypo
                attributions: '',
                crossOrigin: 'anonymous'
            }));
            layers.push(layer);
        })();
    }
    else if (mapSelect === MapStyle.BaiduMap) {
        layers.push(createBaiduMap());
    }
    else if (mapSelect === MapStyle.BaiduHybrid) {
        layers.push(createBaiduMap("sat"));
        layers.push(createBaiduMap("sl"));
    }
    //else if (mapSelect === "ART") {
    //    layer = new ol.layer.Tile();
    //    // ReSharper disable once InconsistentNaming
    //    layer.setSource(new ol.source.OSM({
    //        url: '/Proxy/ArctilerUrl?image={z}/{y}/{x}.png',
    //        crossOrigin: 'anonymous',
    //        attributions:''
    //    }));
    //    layers.push(layer);
    //}
    else if (mapSelect === MapStyle.BingMapHybrid) {
        (function() {
            var layer = new TileLayer({
                title: 'Bing Maps aerial',
                type: 'base',
                visible: true
            });
            layer.setSource(new BingMaps({
                imagerySet: 'AerialWithLabels',
                key: bingMapsKey,
                crossOrigin: 'anonymous'
            }));
            layers.push(layer);
        })();
    }
    //else if (mapSelect === MapStyle.BingMapHybrid) {
    //    (function() {
    //        var layer = new TileLayer({
    //            title: 'Bing Maps aerial',
    //            type: 'base',
    //            visible: true
    //        });
    //        layer.setSource(new BingMaps({
    //            imagerySet: 'Road',
    //            key: BingMapsKey,
    //            crossOrigin: 'anonymous'
    //        }));
    //        layers.push(layer);
    //    })();
    //}
    else if (mapSelect === MapStyle.BingMap) {
        layer = new TileLayer({
            title: 'Bing Maps road',
            type: 'base',
            visible: true
        });
        layer.setSource(new BingMaps({
            imagerySet: 'RoadOnDemand',
            key: bingMapsKey,
            crossOrigin: 'anonymous'
        }));
        layers.push(layer);
    }
    //else if (mapSelect === "BMO") {
    //    layer = new ol.layer.Tile({
    //        title: 'Bing Maps road',
    //        type: 'base',
    //        visible: true
    //    });
    //    layer.setSource(new ol.source.BingMaps({
    //        imagerySet: 'ordnanceSurvey',
    //        key: BingMapsKey,
    //        crossOrigin: 'anonymous'
    //    }));
    //    layers.push(layer);
    //}
    else if (mapSelect === MapStyle.OpenStreetMap) {
        layers.push(new TileLayer({
            title: 'OSM',
            type: 'base',
            visible: true,
            // ReSharper disable once InconsistentNaming
            source: new OSM({
                crossOrigin: 'anonymous'})
        }));
    }
    //else if (mapSelect === "MQR") {
    //    layers.push(new ol.layer.Tile({
    //        title: 'MapQuest OSM',
    //        type: 'base',
    //        visible: true,
    //        source: new ol.source.MapQuest({ layer: 'osm',
    //            crossOrigin: 'anonymous' })
    //    }));
    //}
    //else if (mapSelect === "MQS") {
    //    layers.push(new ol.layer.Tile({
    //        title: 'MapQuest Satellite',
    //        type: 'base',
    //        visible: true,
    //        source: new ol.source.MapQuest({ layer: 'sat',
    //            crossOrigin: 'anonymous' })
    //    }));
    //}
    else if (mapSelect === MapStyle.MapBox) {
        (function() {
            var layer = new TileLayer();
            layer.setSource(new XYZ({
                url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/256/{z}/{x}/{y}?access_token=' +
                    mapBoxKey,
                crossOrigin: 'anonymous'
            }));
            layers.push(layer);
        })();
    }
    else if (mapSelect === MapStyle.MapBoxHybrid) {
        (function() {
            var layer = new TileLayer();
            layer.setSource(new XYZ({
                url: 'https://a.tiles.mapbox.com/v4/mapbox.streets-satellite/{z}/{x}/{y}@2x.png?access_token=' +
                    mapBoxKey,
                crossOrigin: 'anonymous'
            }));
            layers.push(layer);
        })();
    }
	/*
	目前高德的瓦片地址有如下两种：

http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=7和
http://webst0{1-4}.is.autonavi.com/appmaptile?style=7&x={x}&y={y}&z={z}
前者是高德的新版地址，后者是老版地址。

高德新版的参数：

lang可以通过zh_cn设置中文，en设置英文；
size基本无作用；
scl设置标注还是底图，scl=1代表注记，scl=2代表底图（矢量或者影像）；
style设置影像和路网，style=6为影像图，style=7为矢量路网，style=8为影像路网。
总结之：

http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=7 为矢量图（含路网、含注记）
http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=2&style=7 为矢量图（含路网，不含注记）
http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=6 为影像底图（不含路网，不含注记）
http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=2&style=6 为影像底图（不含路网、不含注记）
http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=8 为影像路图（含路网，含注记）
http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=2&style=8 为影像路网（含路网，不含注记）
高德旧版可以通过style参数设置影像、矢量、路网。

总结之：

http://webst0{1-4}.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z} 为影像底图（不含路网，不含注记）
http://webst0{1-4}.is.autonavi.com/appmaptile?style=7&x={x}&y={y}&z={z} 为矢量地图（含路网，含注记）
http://webst0{1-4}.is.autonavi.com/appmaptile?style=8&x={x}&y={y}&z={z} 为影像路网（含路网，含注记）
	*/
    else if (mapSelect === MapStyle.GaoDe) {
        (function() {
            var layer = new TileLayer();
            // ReSharper disable once InconsistentNaming
            layer.setSource(new OSM({
                // ReSharper disable StringLiteralTypo
                 url: "https://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=7",
                // ReSharper restore StringLiteralTypo
                attributions: '',
                crossOrigin: 'anonymous'
            }));
            layers.push(layer);
        })();
    }
    else if (mapSelect === MapStyle.GaoDeHybrid) {
        (function() {
            var layer1 = new TileLayer();
            // ReSharper disable once InconsistentNaming
            layer1.setSource(new OSM({
                // ReSharper disable StringLiteralTypo
                url: "https://wprd0{1-4}.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
                // ReSharper restore StringLiteralTypo
                attributions: '',
                crossOrigin: 'anonymous'
            }));
            layers.push(layer1);
            var layer2 = new TileLayer();
            // ReSharper disable once InconsistentNaming
            layer2.setSource(new OSM({
                // ReSharper disable StringLiteralTypo
                //url: "http://webst0{1-4}.is.autonavi.com/appmaptile?style=8x={x}&y={y}&z={z}",
                url:
                    "https://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=8&ltype=12",
                // ReSharper restore StringLiteralTypo
                attributions: '',
                crossOrigin: 'anonymous'
            }));
            layers.push(layer2);
        })();
    }
    //else if (mapSelect === "TIN") {
    //    layer1 = new ol.layer.Tile();
    //    // ReSharper disable once InconsistentNaming
    //    layer1.setSource(new ol.source.OSM({
    //        // ReSharper disable StringLiteralTypo
    //        url: "http://t{0-7}.tianditu.com/DataServer?T=vec_w&x={x}&y={y}&l={z}",
    //        // ReSharper restore StringLiteralTypo
    //        attributions: '',
    //        crossOrigin: 'anonymous'
    //    }));
    //    layers.push(layer1);
    //    layer2 = new ol.layer.Tile();
    //    // ReSharper disable once InconsistentNaming
    //    layer2.setSource(new ol.source.OSM({
    //        // ReSharper disable StringLiteralTypo
    //        url: "http://t{0-7}.tianditu.com/DataServer?T=cva_w&x={x}&y={y}&l={z}",
    //        // ReSharper restore StringLiteralTypo
    //        attributions:'',
    //        crossOrigin: 'anonymous'
    //    }));
    //    layers.push(layer2);
    //}
    //else if (mapSelect === "TIS") {
    //    layer1 = new ol.layer.Tile();
    //    // ReSharper disable once InconsistentNaming
    //    layer1.setSource(new ol.source.OSM({
    //        // ReSharper disable StringLiteralTypo
    //        url: "http://t{0-7}.tianditu.com/DataServer?T=img_w&x={x}&y={y}&l={z}",
    //        // ReSharper restore StringLiteralTypo
    //        attributions:'',
    //        crossOrigin: 'anonymous'
    //    }));
    //    layers.push(layer1);
    //    layer2 = new ol.layer.Tile();
    //    // ReSharper disable once InconsistentNaming
    //    layer2.setSource(new ol.source.OSM({
    //        // ReSharper disable StringLiteralTypo
    //        url: "http://t{0-7}.tianditu.com/DataServer?T=cva_w&x={x}&y={y}&l={z}",
    //        // ReSharper restore StringLiteralTypo
    //        attributions:'',
    //        crossOrigin: 'anonymous'
    //    }));
    //    layers.push(layer2);
    //}

    return layers;
}

function createBaiduMap(type) {

    var projection = proj.get("EPSG:3857");
    var resolutions = [];
    for (var i = 0; i < 19; i++) {
        resolutions[i] = Math.pow(2, 18 - i);
    }
    var tilegrid = new TileGrid({
        origin: [0, 0],
        resolutions: resolutions
    });

    var baiduSource;
    if (type === "traffic") {
        baiduSource = new TileImage({
            projection: projection,
            tileGrid: tilegrid,
            tileUrlFunction: function (tileCoord) {
                if (!tileCoord) {
                    return "";
                }
                var z = tileCoord[0];
                var x = tileCoord[1];
                var y = tileCoord[2];

                if (x < 0) {
                    x = "M" + (-x);
                }
                if (y < 0) {
                    y = "M" + (-y);
                }

                return 'https://its.map.baidu.com:8002/traffic/TrafficTileService?label=web2D&v=081&level=' + z + '&y=' + y + '&x=' + x + '&time=' + new Date().getTime();
            },
            crossOrigin: 'anonymous'
        });
    }
    else if (type === "sat") {
        baiduSource = new TileImage({
            projection: projection,
            tileGrid: tilegrid,
            tileUrlFunction: function (tileCoord) {
                if (!tileCoord) {
                    return "";
                }
                var z = tileCoord[0];
                var x = tileCoord[1];
                var y = tileCoord[2];

                if (x < 0) {
                    x = "M" + (-x);
                }
                if (y < 0) {
                    y = "M" + (-y);
                }

                return "https://shangetu2.map.bdimg.com/it/u=x=" +
                    x +
                    ";y=" +
                    y +
                    ";z=" +
                    z +
                    ";v=009;type=sate&fm=46&udt=20150504&app=webearth2&v=009&udt=20170606";
            },
            crossOrigin: 'anonymous'
        });
    }
    else if (type === "sl") {
        baiduSource = new TileImage({
            projection: projection,
            tileGrid: tilegrid,
            tileUrlFunction: function (tileCoord) {
                if (!tileCoord) {
                    return "";
                }
                var z = tileCoord[0];
                var x = tileCoord[1];
                var y = tileCoord[2];

                if (x < 0) {
                    x = "M" + (-x);
                }
                if (y < 0) {
                    y = "M" + (-y);
                }

                // ReSharper disable once StringLiteralTypo
                return "https://online3.map.bdimg.com/onlinelabel/?qt=tile&x=" +
                    x +
                    "&y=" +
                    y +
                    "&z=" +
                    z +
                    "&styles=sl&udt=20151021&scaler=1&p=1";
            },
            crossOrigin: 'anonymous'
        });
    } else {
        baiduSource = new TileImage({
            projection: projection,
            tileGrid: tilegrid,
            tileUrlFunction: function (tileCoord) {
                if (!tileCoord) {
                    return "";
                }
                var z = tileCoord[0];
                var x = tileCoord[1];
                var y = tileCoord[2];

                if (x < 0) {
                    x = "M" + (-x);
                }
                if (y < 0) {
                    y = "M" + (-y);
                }

                // ReSharper disable once StringLiteralTypo
                return "https://online3.map.bdimg.com/onlinelabel/?qt=tile&x=" +
                    x +
                    "&y=" +
                    y +
                    "&z=" +
                    z +
                    "&styles=pl&udt=20151021&scaler=1&p=1";
            },
            crossOrigin: 'anonymous'
        });
    }

    return new TileLayer({
        source: baiduSource
    });
}

function isFunction(functionToCheck) {
    var getType = {};
    return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
}

var mapfunction={
   createMapFactory,
   MapStyle,
   EPSGType
};

/*
window.createMapFactory;
window.MapStyle = MapStyle;
window.EPSGType = EPSGType;
*/

export default mapfunction
